
import { Component, Mixins } from 'vue-property-decorator';
import AssignmentChip from '@/components/ui/AssignmentChip.vue';
import CircledAvatar from '@/components/ui/CircledAvatar.vue';
import { VuetifyMixin, MyStudentCoursesMixin, BAIconsMixin } from '@/mixins';
import SourceVideoUploader from '@/components/courses/SourceVideoUploader.vue';
import SourceVideoList from '@/components/courses/SourceVideoList.vue';
import AttachmentsArea from '@/components/forms/AttachmentsArea.vue';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog.vue';
import TextEditor from '@/components/courses/TextEditor.vue';
import { studentCoursesStore } from '@/store';
import { AssignmentStatus } from '@/../types/enums';

@Component({
	components: {
		AssignmentChip,
		AttachmentsArea,
		CircledAvatar,
		ConfirmationDialog,
		TextEditor,
		SourceVideoUploader,
		SourceVideoList
	}
})
export default class AssignmentSubmission extends Mixins(MyStudentCoursesMixin, VuetifyMixin, BAIconsMixin){

	confirmReadyForReview: boolean = false;

	get AssignmentIsCompletedOrReadyForReview(): boolean{
		return this.MyStudentCurrentAssignment.status === AssignmentStatus.Completed;
		return this.MyStudentCurrentAssignment.status === AssignmentStatus.ReadyForReview;
	}

	openUrl(url: string): void{
		window.open(url, "_");
	}
	
	filesBeingUploaded: File[] = [];

	completedUploads: string[] = [];

	get InstructorAddedTemplateFiles(): boolean{
		if(this.$refs.templateFileAttachmentsArea === undefined) return true;
		return this.$refs.templateFileAttachmentsArea.HasFiles;
	}

	$refs: {
		templateFileAttachmentsArea: AttachmentsArea;
		attachmentsArea: AttachmentsArea;
		fileInput: HTMLInputElement;
	};
	get TemplateFileParentId(): string{
		return this.MyStudentCurrentAssignment?.documentUploadId;
	}
	get TemplateFileApiPrefix(): string{
		return `course/${this.MyStudentCurrentCourseId}/student/assignment/documents/${this.MyStudentCurrentAssignment?.documentUploadId}/files`;
	}
	get FileUploadParentId(): string{
		return this.MyStudentCurrentAssignmentId;
	}
	get FileUploadApiPrefix(): string{
		return `course/${this.MyStudentCurrentCourseId}/student/${this.MyStudentId}/assignment/${this.MyStudentCurrentAssignmentId}/files`;
	}

	// selectedVideoFile: File | null = null;
	// uploaderIsShowing = false;

	get currentAssignmentHasSomeSubmissions() : boolean | undefined {
		if(this.MyStudentCurrentAssignment === undefined) return undefined;
		if (this.MyStudentCoursesReady){
			return (this.MyStudentCurrentAssignment?.status === AssignmentStatus.Completed || this.MyStudentCurrentAssignment?.status === AssignmentStatus.ReadyForReview);
		}
		return undefined
	}

	get currentAssignmentHasNoSourceVideos(): boolean | undefined {
		if(this.MyStudentCurrentAssignment === undefined) return undefined;
		if (this.MyStudentCoursesReady){
			return this.MyStudentCurrentAssignment?.sourceVideoSubmissionIds.length === 0;
		}
		return undefined
	}

	get currentAssignmentRubric() {
		if(this.MyStudentCurrentAssignment === undefined) return undefined;
		return this.MyStudentCurrentAssignment?.rubric;
	}

	detectVideoFiles($event: Event) {
		const tempFiles = ($event.target as HTMLInputElement).files;

		for (let fIdx = 0; fIdx < tempFiles.length; fIdx++) {
			console.log("the selected file name is: ", tempFiles[fIdx].name)
			console.log('Other file details: ', tempFiles[fIdx]);
			this.filesBeingUploaded.push(tempFiles[fIdx]);
		}
	}

	removeFileAtIndex(fileIndex: number) {
		console.log('IN remove file at index and the index is: ', fileIndex);
		this.filesBeingUploaded.splice(fileIndex, 1);
	}

	// async finishedUploadingVideo($event: any) {
	//   // console.log("Finished uploading the video - event is: ", $event );
	//   if ($event.status === 'success') {
	//     const updateStoreResult = await studentCoursesStore.addSourceVideoToAssignmentById({
	//       sourceVideoId: $event.data, 
	//       courseId: this.MyStudentCurrentCourseId, 
	//       studentId: this.MyCoachId,
	//       assignmentId: this.MyStudentCurrentAssignmentId
	//     });
	//     // console.log("In finishing operation - the store update result is: ", updateStoreResult)
	//     // update the clipEditorStore to fetch the new 
	//     clipCreationToolStore.loadSourceVideosForEvaluationAssignment(this.MyStudentCurrentAssignmentId);
	//   }
	//   // this.uploaderIsShowing = false;
	// }

	async markAssignementReadyForReview() {
		// console.log("Assignment is ready for review.");
		
		if(this.$refs.attachmentsArea){
			await this.$refs.attachmentsArea.uploadAllFiles(this.FileUploadParentId);
		}

		// I think there could be some timing issure here if the Instructor completes the review while new videos were being uploaded.
		if (this.MyStudentCurrentAssignment?.status !== AssignmentStatus.Completed && this.MyStudentCurrentAssignment?.status !== AssignmentStatus.ReadyForReview) {
			await studentCoursesStore.updateAssignmentStatus({
				theStatus: AssignmentStatus.ReadyForReview, 
				courseId: this.MyStudentCurrentCourseId, 
				studentId: this.MyCoachId,
				assignmentId: this.MyStudentCurrentAssignmentId
			});
		}

		this.returnToStudentCoursePage();
	}

	goBack() {
		this.returnToStudentCoursePage();
	}

	returnToStudentCoursePage() {
		this.$router.push({
			name: 'StudentCoursePage',
			params:{
				courseId: this.MyStudentCurrentCourseId,
			}
		});
	}

}
